import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { ThemeType } from '../interfaces/theme.type';
import { toggleGlobalTheme } from '../utils/toggle-global-theme';

// const isElectron = !!window.require;
// let ipcRenderer: Electron.IpcRenderer;

// if (isElectron) {
//   ({ ipcRenderer } = window.require('electron'));
// }

const themeAtom = atom<ThemeType>('system');

const setTheme = (theme: ThemeType): void => getDefaultStore().set(themeAtom, theme);

export const useCurrentTheme = (): ThemeType => useAtomValue(themeAtom);

export const switchTheme = (theme: ThemeType): void => {
  setTheme(theme);
  toggleGlobalTheme(theme);
  localStorage.setItem('theme', theme);

  // if (isElectron) {
  //   ipcRenderer && ipcRenderer.invoke('set-app-config', { theme });
  //   ipcRenderer && ipcRenderer.invoke('send-theme-to-electron', theme);
  // }
};
