import { Button } from '@mui/material';
import {PaymentElement} from '@stripe/react-stripe-js';

const CheckoutForm = () => {
  return (
    <form>
      Оплатить подписку жи есть
      <PaymentElement />
      <Button>Submit</Button>
    </form>
  );
};

export default CheckoutForm;