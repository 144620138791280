import { Button, message, Modal, Select, Tooltip } from 'antd';
import moment from 'moment';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// import { updateFeatures, confirmEmailAddress, IUpdateFeatures } from './api';
import ChangeEmailComponent from './email';
import PasswordChangeForm from './PasswordChangeForm';
import {
  AccountEmail,
  AlignedButton,
  OverviewGrid,
  OverviewRowTitle,
  SelectCustom,
  SubtitleTransfer,
} from './styles';
// import { NEW_FEATURES, setFeatures } from '../../../state/feature-toggle/new-features';
// import useGlobalAntStyles from '../../../hooks/use-global-ant-styles';
// import { history } from '../../../services';
import { userContext } from '../../state/user.context';
// import { updatePricingData, usePricingData } from '../../../state/pricing-data.atom';
import { switchTheme, useCurrentTheme } from '../../state/theme.atom';
// import { SelectWorkspace } from '../../../ui/workspaceSelect';
// import { openWebsitePricing } from '../../../utils/open-site';
// import { sendActionAnalytics, updateDefaultWorkspace } from '../../common/api';
// import LoadingPage from '../../common/loading-page';
// import ImportDolphinProfilesModal from '../../modalsComponents/components/import-dolphin-profiles';
// import { TabWrapper } from '../menu/tab-wrapper';
import { PageTitle } from '../styles';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from '../../pricing/stripe';
import { API_BASE_URL } from '../../constants';

// const isElectron = !!window.require;
// let ipcRenderer: Electron.IpcRenderer;
// if (isElectron) {
//   ({ ipcRenderer } = window.require('electron'));
// }

export const OverviewPage: FC = () => {
  const { t: translation, i18n } = useTranslation();

  // const pricingData = usePricingData();

  const [language, setLanguage] = useState(i18n.language);
  const [isEmailChangeModalOpened, setIsEmailChangeModalOpened] = useState<boolean>(false);
  const [isPasswordChangeModalOpened, setIsPasswordChangeModalOpened] = useState<boolean>(false);
  const [isShowImportDolphinModal, setIsShowImportDolphinModal] = useState<boolean>(false);
  const [isPaymentModalOpened, setIsPaymentModalOpened] = useState<boolean>(false);
  const [showStripeForm, setShowStripeForm] = useState<boolean>(false);
  const [user, setUser] = useState<any>({});
  const [clientSecret, setClientSecret] = useState<string>('');
  // let clientSecret = '';
  // const [isNewDesign, setIsNewDesign] = useState<boolean>(!!(NEW_FEATURES.header && NEW_FEATURES.drawer));

  const theme = useCurrentTheme();
  const stripePromise = loadStripe('pk_live_51QBfh8KocVE5OUKbBIHNaoBABB9wlGEND3xhq4VgrF2VouAfcqPjjNpRuPIKeJlx5Fxac7xnLtNw73qRAyOpVj4f00qiMwcK2V');
  useEffect(() => {
    fetch(`${API_BASE_URL}/user`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    }
  }).then((res) => res.json()).then((res) => {setUser(res); console.log('res', res)});
  }, []);

  // const {
  //   _id = '',
  //   email,
  //   isEmailConfirmed,
  //   updateUser,
  //   profiles: userProfiles,
  //   plan: userPlan,
  //   isQuickSettingsEnabled,
  //   defaultWorkspace,
  // } = useContext(userContext);

  // const {
  //   id: workspaceId,
  //   me,
  //   updateWorkspace,
  //   members,
  // } = useContext(workspaceContext);

  // const { areAntStylesLoading } = useGlobalAntStyles();

  // const {
  //   maxProfiles: planMaxProfiles,
  //   name: namePlan,
  // } = userPlan;

  useEffect(() => {
    const locale = localStorage.getItem('@gologin:selected_language');
    setLanguage(locale || i18n.language);
  }, []);

  const confirmEmail = (): void => {
    // confirmEmailAddress();
    message.success(translation('personalArea.overview.AlertEmailConfirm'));
    // history.push('/');
  };

  const changeLanguage = (code: string): void => {
    i18n.changeLanguage(code).then(() => {
      setLanguage(i18n.language);
      moment.locale(i18n.language);
      localStorage.setItem('@gologin:selected_language', i18n.language);
      const translationData = {
        language: i18n.language,
        closeAppTitle: translation('closeApp.title'),
        closeAppMessage: translation('closeApp.message'),
        closeAppBtnClose: translation('closeApp.btnClose'),
        closeAppBtnCancel: translation('closeApp.btnCancel'),
      };

      // ipcRenderer && ipcRenderer.invoke('set-app-config', { language:  i18n.language });
      // ipcRenderer && ipcRenderer.invoke('send-translation-data-to-electron', translationData);
    });
  };

  const openPricing = () => {
    setIsPaymentModalOpened(true);
  }

  const onEmailChanged = (newEmail: string): void => {
    setIsEmailChangeModalOpened(false);

    // if (me) {
    //   me.email = newEmail;
    // }

    // const newMembers = members.map(member => member.invitedByEmail === email ?
    //   { ...member, invitedByEmail: newEmail } :
    //   member,
    // );

    // updateWorkspace({ members: newMembers });
    // updateUser({
    //   isEmailConfirmed: false,
    //   email: newEmail,
    // });

    // const { userData } = pricingData;
    // userData.email = newEmail;
    // updatePricingData({ ...pricingData, userData });
  };

  const renderEmail = (emailToRender: string): JSX.Element|string => {
    const EMAIL_LENGTH = 22;

    if (emailToRender.length > EMAIL_LENGTH) {
      return (
        <Tooltip title={emailToRender}>
          {emailToRender.slice(0, EMAIL_LENGTH)}
          ...
        </Tooltip>
      );
    }

    return emailToRender;
  };

  const createPaymentSession = async ({discount, plan}: {discount: string, plan: string}) => {
    const createCheckoutSession = await fetch(`${API_BASE_URL}/billing/stripe/payment-sessions`, {
      method: 'POST',
      body: JSON.stringify({
        discount,
        plan,
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('jwt')}`,
      }
    }).then((res) => res.json());
    console.log('createCheckoutSession', createCheckoutSession);
    if (createCheckoutSession.url) {
      window.open(createCheckoutSession.url, '_blank');
    }
    // setClientSecret(createCheckoutSession.clientSecret)
    // setShowStripeForm(true)
    // console.log('createCheckoutSession', createCheckoutSession);
  }

  // let profilesText = `${namePlan} (${userProfiles}/${planMaxProfiles} ${translation('base.profiles')})`;
  // if (!planMaxProfiles) {
  //   profilesText = `${namePlan} (${userProfiles} ${translation('base.profiles')})`;
  // }

  // const openCheckoutPage = (): void => {
  //   sendActionAnalytics('clicked buy plan', { actionInfo: 'overview' });
  //   openWebsitePricing({ workspaceId, isQuickSettingsEnabled });
  // };

  // const changeDefaultWorkspace = (newWorkspaceId: string): void => {
  //   updateDefaultWorkspace(newWorkspaceId);
  //   updateUser({
  //     defaultWorkspace: newWorkspaceId,
  //   });
  // };

  // const switchDesign = async (state: boolean): Promise<void> => {
  //   // if (state === isNewDesign) {
  //   //   return;
  //   // }

  //   setIsNewDesign(state);
  //   const features: IUpdateFeatures = {
  //     header: state,
  //     drawer: state,
  //   };

  //   await updateFeatures(features);
  //   setFeatures(features);
  // };

  // const renderDefaultWorkspace = (): JSX.Element|null => {
  //   if (!NEW_FEATURES.workspaces) {
  //     return null;
  //   }

  //   return (
  //     <>
  //       <OverviewRowTitle style={{ fontSize: 12, color: 'var(--767676-personal-area)' }}>
  //         {translation('personalArea.overview.defaultWorkspace')}
  //       </OverviewRowTitle>
  //       <SelectWorkspace
  //         userId={_id || ''}
  //         selectedWorkspaceId={defaultWorkspace || ''}
  //         setSelectedWorkspaceId={changeDefaultWorkspace}
  //         isOwnerOnly={true}
  //         placeholder={translation('personalArea.overview.selectDefaultWorkspace')}
  //         showAddWorkspace={true}
  //         needFoldersList={false}
  //       />
  //     </>
  //   );
  // };

  // if (areAntStylesLoading) {
  //   return <LoadingPage />;
  // }

  const getPaymentModal = (): JSX.Element => {
    if (showStripeForm) {
      console.log('clientSecret', clientSecret);
      return (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <CheckoutForm />
        </Elements>
      )
    }
    return (
      <div>
        <Button onClick={() => createPaymentSession({discount: '5f9695b11f9a50d848314702', plan: '6724d0a7fdcb2bc43d1a4214'})} style={{marginRight: 20}}>19$/month</Button>
        <Button onClick={() => createPaymentSession({discount: '5f9695b11f9a50d848314701', plan: '6724d0a7fdcb2bc43d1a4214'})}>130$/year (11$/month) </Button>
      </div>
    )
  }

  return (
    <div style={{ margin: '40px'}}>
      <PageTitle>
        {translation('personalArea.overview.title')}
      </PageTitle>
      <OverviewGrid>
        <OverviewRowTitle>
          User Id
        </OverviewRowTitle>
        <div />
        {user.sub}
        {/* <AlignedButton
          type='default'
          onClick={(): void => setIsPasswordChangeModalOpened(true)}
        >
          {translation('changePassInSettings.changePassword')}
        </AlignedButton> */}
        <OverviewRowTitle>
          {translation('personalArea.overview.subTitleEmail')}
        </OverviewRowTitle>
        <div style={{ display: 'flex' }}>
          {renderEmail(user.email || '')}
          <AccountEmail>
            {!user.isEmailConfirmed && (
              <Tooltip title={translation('personalArea.overview.TooltipConfirmEmail')}>
                {/* <Icon type='info-circle' style={{ color: 'var(--FF0000-personal-area-overview)', marginLeft: 4 }} /> */}
              </Tooltip>
            )}
            <Tooltip title={translation('personalArea.overview.editBtnText')}>
              {/* <Icon type='edit' style={{ marginLeft: 5 }} onClick={(): void => setIsEmailChangeModalOpened(true)} /> */}
            </Tooltip>
          </AccountEmail>
        </div>
        {!user.isEmailConfirmed ? (
          <AlignedButton
            type='default'
            onClick={confirmEmail}
          >
            {translation('personalArea.overview.confirmSubmitBtnText')}
          </AlignedButton>
        ) : <div />}
        {/* {!NEW_FEATURES.workspaces ? (
          <>
            <OverviewRowTitle>
              {translation('personalArea.overview.subTitlePlan')}
            </OverviewRowTitle>
            <span>
              {profilesText}
            </span>
            <Button
              type='primary'
              onClick={openCheckoutPage}
            >
              {translation('personalArea.overview.upgradeSubmitBtnText')}
            </Button>
          </>
        ) : null} */}
        <OverviewRowTitle>
          {translation('base.password')}
        </OverviewRowTitle>
        <div />
        <AlignedButton
          type='default'
          onClick={(): void => setIsPasswordChangeModalOpened(true)}
        >
          {translation('changePassInSettings.changePassword')}
        </AlignedButton>
        <OverviewRowTitle>
          {translation('base.selectLanguage')}
        </OverviewRowTitle>
        <div />
        <Select
          style={{
            marginLeft: 0,
            width: 180,
          }}
          value={language}
          onSelect={(code: string): void => {
            changeLanguage(code);
          }}
        >
          <Select.Option value='en'>
            English
          </Select.Option>
          <Select.Option value='ru-RU'>
            Русский
          </Select.Option>
          <Select.Option value='fr'>
            Français
          </Select.Option>
          <Select.Option value='pt'>
            Português
          </Select.Option>
          <Select.Option value='vi-VN'>
            Tiếng việt
          </Select.Option>
          <Select.Option value='zh-Hans-CN'>
            中文
          </Select.Option>
          <Select.Option value='ja'>
            日本
          </Select.Option>
        </Select>
        {/* <SubtitleTransfer>
          {translation('personalArea.transfer.optionText')}
        </SubtitleTransfer>
        <div />
        <Button
          type='default'
          onClick={(): void => setIsShowImportDolphinModal(true)}
        >
          {translation('personalArea.transfer.buttonStart')}
        </Button> */}
          <>
            <OverviewRowTitle>
              {translation('personalArea.overview.interfaceTheme')}
            </OverviewRowTitle>
            <div />
            <Select
              style={{ marginLeft: 0, width: 100 }}
              value={theme}
              onSelect={switchTheme}
            >
              <Select.Option value='system'>
                {translation('personalArea.overview.system')}
              </Select.Option>
              <Select.Option value='light'>
                {translation('personalArea.overview.light')}
              </Select.Option>
              <Select.Option value='dark'>
                {translation('personalArea.overview.dark')}
              </Select.Option>
            </Select>
          </>
        {/* <OverviewRowTitle>
          {translation('personalArea.overview.switchDesignTitle')}
        </OverviewRowTitle>
        <div />
        <SelectCustom
          style={{ marginLeft: 0 }}
          value={1}
          onSelect={(value): Promise<void> => switchDesign(!!value)}
        >
          <Select.Option value={1}>
            {translation('personalArea.overview.switchDesignNew')}
          </Select.Option>
          <Select.Option value={0}>
            {translation('personalArea.overview.switchDesignOld')}
          </Select.Option>
        </SelectCustom> */}
        <OverviewRowTitle>
          Plan
        </OverviewRowTitle>
        <div style={{ display: 'flex' }}>
          {renderEmail(user?.lastPayment?.planName || '')}
          
        </div>
        <AlignedButton
            type='default'
            onClick={openPricing}
          >
            Change Plan
          </AlignedButton>
      </OverviewGrid>
      <Modal
        visible={isEmailChangeModalOpened}
        onCancel={(): void => setIsEmailChangeModalOpened(false)}
        title={translation('personalArea.changeEmailModal.title')}
        footer={null}
      >
        <ChangeEmailComponent
          initialEmail={user.email}
          onConfirm={onEmailChanged}
        />
      </Modal>
      <Modal
        visible={isPaymentModalOpened}
        onCancel={(): void => setIsPaymentModalOpened(false)}
        title='Unlimited access'
        footer={null}
      >
        {getPaymentModal()}
      </Modal>
      <Modal
        visible={isPasswordChangeModalOpened}
        onCancel={(): void => setIsPasswordChangeModalOpened(false)}
        title={translation('changePassInSettings.changePassword')}
        footer={null}
      >
        <PasswordChangeForm
          onSubmit={(): void => setIsPasswordChangeModalOpened(false)}
        />
      </Modal>
      {/* <ImportDolphinProfilesModal
        visible={isShowImportDolphinModal}
        onClose={() => setIsShowImportDolphinModal(false)}
      />
      {renderDefaultWorkspace()} */}
    </div>
  );
};
