import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SignIn from './sign-in/SignIn';
import SignUp from './sign-up/SignUp';
import { OverviewPage } from './settings/overview';
import { SuccessHtmlPage } from './pricing/page-payment-success';
import { FailedHtmlPage } from './pricing/page-payment-failed';
import { HtmlContent } from './components/payment-redirect-pages/success';

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/settings" element={<OverviewPage />} />
          <Route path="/checkout/success" element={<HtmlContent html={SuccessHtmlPage} />} />
          <Route path="/checkout/failed" element={<HtmlContent html={FailedHtmlPage} />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
