import { ThemeType } from '../interfaces/theme.type';

const getSelectedThemeColor = (currentTheme: ThemeType): Omit<ThemeType, 'system'> => {
  if (currentTheme !== 'system') {
    return currentTheme;
  }

  let selectedThemeColor = 'light';
  const darkThemeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
  if (darkThemeMediaQuery.matches) {
    selectedThemeColor = 'dark';
  }

  return selectedThemeColor;
};

export const toggleGlobalTheme = (to: ThemeType): void => {
  const [body] = document.getElementsByTagName('body');
  const currentTheme = getSelectedThemeColor(to);
  body.classList.add('disable-animations');
  if (currentTheme === 'dark') {
    body.classList.add('theme-dark');
    body.classList.remove('theme-light');
  } else {
    body.classList.add('theme-light');
    body.classList.remove('theme-dark');
  }

  setTimeout(() => body.classList.remove('disable-animations'), 50);
};
